.footer-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.11px;
  letter-spacing: -0.04em;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}

@media only screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
  }
}
