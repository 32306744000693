.secondary-main-card {
  margin: 10% 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.secondary-main-card h1 {
  font-family: Koulen;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  color: rgba(18, 18, 18, 1);
  margin: 0 20%;
}

.secondary-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  padding: 0px 20px;
  margin: 40px 5%;
}

.secondary-box-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 13px;
  background: rgba(239, 233, 226, 1);

  padding: 25px;
  width: 30%;
  position: relative;
  box-shadow: 0px 6px 13px 0px rgba(18, 18, 18, 0.15);
}

.secondary-box-1 p {
  color: rgba(18, 18, 18, 1);
  text-align: center;
  margin-top: 20px;
  padding: 15px;
}

@media only screen and (max-width: 1100px) {
  .secondary-main-card h1 {
    text-align: left;
    margin: 0 10%;
  }
  .secondary-box {
    flex-direction: column;
  }
  .secondary-box-1 {
    width: 100%;
    margin-bottom: 20px;
  }
}
