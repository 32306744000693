.contact-box {
  background-image: url("../../assets/contact-bg.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.contact-lower-gradient {
  background-image: url("../../assets/contact-lower-gradient.svg");
  height: 100px;

  margin-top: -100px;
}

.contact-box-text {
  /* background-image: url("../../assets/contact-text-bg.svg"); */
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-box-text h1 {
  font-family: Koulen;
  font-size: 100px;
  font-weight: 400;
  text-align: center;
  color: rgba(18, 18, 18, 1);
}

.contact-btn-box {
  display: flex;
  flex-direction: row;
  margin: 50px 0px;
}

.contact-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  margin-left: 20px;
}

.contact-btn p {
  color: rgba(239, 233, 226, 1);
  margin-left: 10px;
}

@media only screen and (max-width: 1100px) {
  .contact-box-text h1 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .contact-box-text h1 {
    font-size: 50px;
  }
  .contact-btn-box {
    flex-direction: column;
  }
  .contact-btn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .contact-box-text h1 {
    font-size: 40px;
  }
}
