.hero-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12% 5%;
  position: relative;
}

.hero-section h1 {
  font-family: Koulen;
  font-size: 70px;
  font-weight: 400;

  text-align: left;
  color: rgba(18, 18, 18, 1);
  width: 50%;
}

.animation-box {
  position: absolute;
  left: 0%;
  top: -80%;
  z-index: 100;
}

@media only screen and (max-width: 1100px) {
  .hero-section {
    flex-direction: column;
  }

  .hero-section h1 {
    width: 100%;
    font-size: 50px;
  }

  .animation-box {
    top: 100%;

    z-index: 100;
  }
}
