@font-face {
  font-family: "Cera Pro";
  src: url("./fonts/cerapro-black.otf") format("otf"),
    url("./fonts/cerapro-bold.otf") format("otf"),
    url("./fonts/cerapro-light.otf") format("otf"),
    url("./fonts/cerapro-medium.otf") format("otf"),
    url("./fonts/cerapro-regular.otf") format("otf"),
    url("./fonts/cerapro-thin.otf") format("otf");

  /* Add other font weights and styles if needed */
}

body {
  background-color: rgba(254, 252, 246, 1);

  font-family: "Cera Pro", sans-serif; /* Use Cera Pro as the default font */
  overflow-x: hidden;
}

p {
  margin: 0;
}
