.main-box {
  position: relative;
  border-radius: 32px;
  box-shadow: 0px 0px 26.64px 0px rgba(18, 18, 18, 0.3);
  background: rgba(18, 18, 18, 1);
  color: rgba(254, 252, 246, 1);
  padding: 40px 0px;
  margin: 2% 5%;
}

.main-box h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-family: "Koulen", sans-serif;
  font-weight: 400;
  padding: 0px 40px;
}

.main-box > p {
  font-size: 16px;
  width: 35%;
  padding: 0px 40px;
}

.nested-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  padding: 0px 20px;
}

.nested-box-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 13px;
  background-color: rgba(254, 252, 246, 1);
  padding: 25px;
  width: 30%;
  position: relative;
}

.nested-box-1 p {
  color: rgba(18, 18, 18, 1);
  text-align: center;
  margin-top: 20px;
}

.nested-box-1-gradient {
  position: absolute;
  /* background: linear-gradient(
    90deg,
    #f2c8da 21.42%,
    #cb9da9 60.71%,
    #cfa0a2 100%
  ); */
  /* background: linear-gradient(
    90deg,
    rgba(242, 200, 218, 0.05) 21.42%,
    rgba(203, 157, 169, 0.05) 60.71%,
    rgba(207, 160, 162, 0.05) 100%
  ); */
  height: 30px;
  width: 100%;
  top: 0px;
  overflow: hidden;
  border-radius: 13px;
  background-image: url("../../assets/main-nested-gradient.svg");
  opacity: 0.5;
}

.main-box-gradient {
  position: absolute;
  /* background: linear-gradient(
    90deg,
    #f2c8da 21.42%,
    #cb9da9 60.71%,
    #cfa0a2 100%
  ); */
  /* background: linear-gradient(
    90deg,
    rgba(242, 200, 218, 0.05) 21.42%,
    rgba(203, 157, 169, 0.05) 60.71%,
    rgba(207, 160, 162, 0.05) 100%
  ); */
  height: 100%;
  width: 50%;

  overflow: hidden;
  border-radius: 13px;
  background-image: url("../../assets/main-gradient.svg");
  background-position: center;
  background-repeat: no-repeat;
  top: 0px;
  right: 0px;

  /* left: 40%; */
}

@media only screen and (max-width: 1100px) {
  /* Your CSS styles for tablets and mobile devices go here */
  .main-box {
    margin-top: 50%;
  }
  .main-box > p {
    width: 100%;
  }
  .nested-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .nested-box-1 {
    width: 100%;
    margin-bottom: 20px;
  }
}
