.navbar-btn {
  border-radius: 90px;
  padding: 15px;
  box-shadow: 0px 0px 14px 0px rgba(239, 233, 226, 0.12);

  box-shadow: 0px 0px 13px 0px rgba(168, 146, 128, 0.5);

  background-color: rgba(18, 18, 18, 1);
}

.navbar-btn-text {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: rgba(239, 233, 226, 1);
}

nav {
  margin: 20px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1100px) {
  nav {
    margin: 10px;
  }

  .navbar-btn {
    padding: 10px;
  }

  .navbar-btn-text {
    font-size: 14px;
  }
}
